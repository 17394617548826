import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import { readingTime } from 'reading-time-estimator';

import { BlogBreadCrumb, BlogCta, ClockBlog, ContactUsForm, Footer, LatestBlogs, MetaDecorator, Navbar, Popup, ShareSocial } from '../../Components'
import { blog6, blog6ComputerVision, blog6Expert, blog6GenAi, blog6Nlp, blog6ReinforceLearn, blog6Robotics, aiBlogAvatar } from '../../assets';
import { handleScrollForActiveSection } from '../../utils/blogActiveSection';


const domains = [
    {
        title: "The Magic of Generative AI",
        image: blog6GenAi,
        textIntro: "Imagine sitting down with a machine that can create anything you want—a poem, a painting, or even a song. Sounds like magic, right? That’s the power of <b>Generative AI</b>. Whether it’s writing, coding, or composing music, this technology can create new content entirely from scratch based on the data it’s trained on. It’s more than just processing information—it’s creating something new. <br /><br /> Think of <b>ChatGPT</b> or <b>DALL-E</b>, tools that generate content in seconds. Now imagine what you could do with that kind of power in your hands!",
        bullets: [
            "<b>ChatGPT</b>: ChatGPT is a generative artificial intelligence chatbot developed by OpenAI.",
            "<b>DALL-E</b>: Employed by designers to create new concepts for advertising campaigns.",
            "<b>Github Copilot</b>: Helping developers generate code snippets, speeding up the development process.",
        ],
        text: "Generative AI is pushing the boundaries of creativity, automating tasks that once required hours of human effort. But how does it understand your language to generate that content? Let’s dive into <b>Natural Language Processing (NLP)</b> to find out.</b>",
    },
    {
        title: "Talking to Machines: The World of NLP",
        image: blog6Nlp,
        textIntro: "Do you remember a time when computers could only understand commands in code? Those days are long gone. With <b>Natural Language Processing (NLP)</b>, computers now understand and respond to human language. From chatting with a customer service bot to using voice assistants like Siri or Alexa, NLP makes it possible. <br /><br /> Think about the last time you used autocomplete or grammar check—NLP was behind it all, making life a bit easier.",
        bullets: [
            "<b>Chatbots</b>: Assists customers with their queries by interacting in their own language",
            "<b>Smart Assistants</b>: Assisting users with tasks like setting reminders, controlling smart devices, and answering questions.",
            "<b>Search Engines</b>: Utilizing NLP to provide more accurate and relevant search results.",
            "<b>Text Summarization, Autocorrect, Autocomplete etc..</b>: Helping millions of people improve their writing by detecting grammar mistakes using NLP.",
        ],
        text: "It’s amazing that machines can understand our languages. But wouldn’t it be even more impressive if they could understand visuals too? That’s where <b>Computer Vision</b> comes in.</b>",
    },
    {
        title: "Seeing the World Through AI: Computer Vision",
        image: blog6ComputerVision,
        textIntro: "Imagine a world where machines not only take pictures but actually understand them. <b>Computer Vision</b> allows AI to interpret images and videos, giving meaning to visual data. Whether it’s a self-driving car navigating streets or a camera identifying objects, AI is helping machines see the world as we do. <br /><br /> Can you picture how industries like healthcare, retail, and automotive are using this technology to improve safety and efficiency?",
        bullets: [
            "<b>Self Driving Cars</b>: Using computer vision to detect obstacles, traffic signs, and pedestrians to enable self-driving cars.",
            "<b>Surveillance Cameras</b>: Used to enhance security by identifying passengers quickly.",
            "<b>Fault Detection</b>: Computer Vision systems can monitor production lines, identifying faults in products, such as color variations, and material disctinction.",
        ],
        text: "With Computer Vision improving accuracy, imagine the possibilities when machines not only see but can perform physical tasks. That brings us to <b>Robotics.</b>",
    },
    {
        title: "Robots That Think: The Rise of AI-Powered Robotics",
        image: blog6Robotics,
        textIntro: "Now, picture a robot in a factory that assembles products while detecting obstacles, adjusting to its environment, and working alongside human workers. AI-driven Robotics is making this possible, allowing machines to perform precise, complex tasks.<br /><br /> Imagine a future where robots perform surgeries or assist in disaster relief efforts. It’s not science fiction anymore—it’s reality. <br /><br /> <b>Robotics.</b>, powered by AI, is already transforming industries by combining mechanical systems with intelligent decision-making. These machines are equipped with sensors and AI algorithms, enabling them to not only perform tasks but also learn from their environment, making them smarter over time.",
        bullets: [
            "<b>Surgical Robots</b>",
            "<b>Industrial Robots</b>",
            "<b>Domestic Robots</b>",
            "<b>Digital Assistants</b>",
            "<b>Autonomous Vehicles</b>",
        ],
        text: "Wouldn’t it be fascinating to see how these machines learn from their mistakes just like humans do? Welcome to the world of <b>Reinforcement Learning</b>.",
    },
    {
        title: "Learning from Mistakes: Reinforcement Learning",
        image: blog6ReinforceLearn,
        textIntro: "Being humans, we learn from our mistakes. We receive rewards on our success and get penalty on failures as well. Want to see a machine doing same? That’s where the concept of <b>Reinforcement Learning</b> comes in. <br /><br /> In Reinforcement Learning based AI, machines learn from trial and error. The errors improve their performance, making them more efficient over time. Their decision making keeps getting better by rewards and penalties on successes and failures respectively.",
        bullets: [
            "<b>Image Processing</b>: Uses artificial intelligence to analyze and enhance images. It involves tasks like pattern recognition, object detection, and image classification.",
            "<b>Recommendation Systems</b>: Used by platforms like Netflix and YouTube to personalize content by learning from user preferences and behavior.",
            "<b>Google DeepMind's AlphaGo</b>: Learning from every game it plays to defeat world champions in the game of Go.",
            "<b>Traffic Control</b>: Continuously learning to improve navigation and decision-making in dynamic environments.",
        ],
        text: "With Reinforcement Learning, machines continually enhance their efficiency. But some AI systems don’t rely on trial and error alone—they use expert knowledge to make informed decisions. This brings us to <b> Expert Systems.</b>.",
    },
    {
        title: "Expert Systems: Machines with Human-Like Expertise",
        image: blog6Expert,
        textIntro: "Think of having a machine that can solve your complex tasks like an expert! That’s what expert systems are designed for. These systems are built to mimic the decision-making ability of a human expert in specific fields. They use a vast collection of knowledge from domain experts to analyze situations and deliver accurate solutions. <br /><br /> The <b>Expert Systems</b> get trained directly from professionals in that particular field, incorporating years of human experience into their knowledge base. Once trained, they don’t just follow simple rules—they apply complex logic and reasoning to make decisions on their own. This makes them capable of solving problems that require deep expertise.",
        bullets: [
            "<b>Cancer Decision Support Tool (CDS)</b>: tools are an aid to clinical decision-making, to assist GPs in their decisions about whether to refer or request further diagnostic investigation.",
            "<b>IBM Watson for Oncology</b>: Assisting doctors in diagnosing cancer and suggesting treatment options based on vast amounts of medical data.",
            "<b>MYCIN</b>: An early expert system used for diagnosing bacterial infections and recommending treatments in medical settings.",
            "<b>Agricultural Expert Systems</b>: Helping farmers make decisions on crop management, pest control, and irrigation to improve yield and efficiency.",
        ],
        text: "<b>Expert systems</b> represent one of the most advanced forms of AI by integrating human expertise into a machine’s decision-making process. Their use in industries such as healthcare, law, and finance showcases how AI can replicate human expertise and enhance decision-making in critical areas.",
    }
];

export const AIApplicationDomainsBlog = () => {
    const [activeSection, setActiveSection] = useState("");
    const [readTime, setReadTime] = useState(null);

    // Popup
    const [popup, setPopup] = useState({ show: false, state: null, message: "" });


    useEffect(() => {
        // Calculating reading time
        const blogContent = document.getElementById("blogContent").innerText;
        setReadTime(`${readingTime(blogContent).minutes} min read`);

        // Scrolling for table of contents' active section
        const cleanup = handleScrollForActiveSection(setActiveSection);
        return cleanup;
    }, []);

    const getId = (item) => item.toLowerCase().replace(/\./g, "").replace(/ /g, "-");


    return (
        <>
            {/* Meta Data */}
            <MetaDecorator
                title="AI Based on Application Domains | Techieonix International"
                imageUrl="https://stotechieonixassets.file.core.windows.net/techieonix-assets/blog-6.jpg?sv=2022-11-02&ss=f&srt=o&sp=r&se=2025-10-28T03:57:49Z&st=2024-10-27T19:57:49Z&spr=https,http&sig=FJsQ8eGyXYsp16U4Cj7gjWiBzABFNSeDpVlwJGtVsTk%3D"
                type="article"
            />
            
            {/* Navbar */}
            <Navbar white={true} />

            {/* Breadcrumb */}
            <BlogBreadCrumb category="Artificial Intelligence" title="AI Based on Application Domains" />




            {/* Blog */}
            <section className="px-5 md:px-10 xl:px-[--px-xl] lg:pt-10 xl:pb-10 space-y-10 center theme-section">

                {/* Header Image */}
                <div className="space-y-2 blog-head-position">
                    <img src={blog6} alt="AI Blog" title="AI Blog" className="rounded-[--round]" />
                    <p className="pt-2.5 border-t text-xs text-[#23232666] text-center">
                        Image taken from Freepik
                    </p>
                </div>


                {/* Header / Intro */}
                <div className="pb-5 md:pt-5 space-y-4 lg:space-y-6 border-b border-[--black-primary-10] blog-head-position">
                    <div className="space-y-3">
                        <h1>AI Based on Application Domains</h1>
                        <div className="flex gap-x-5 text-xs">
                            <div>October 25, 2024</div>
                            <div className="flex items-center gap-x-1 px-2">
                                <ClockBlog fill="#232326" />
                                <span>{readTime}</span>
                            </div>
                        </div>
                    </div>
                    <span className="chip">Artificial Intelligence</span>
                    <div className="flex gap-x-2 lg:gap-x-4 items-center">
                        <img className='size-[52px]' src={aiBlogAvatar} alt="avatar" />
                        <div>
                            <h5>Faheela Waseem</h5>
                            <p className="text-xs">Frontend Developer at Techieonix</p>
                        </div>
                    </div>
                </div>




                <div className="max-lg:space-y-10 lg:flex gap-x-6">

                    {/* Table of Contents */}
                    <div className="space-y-3 sm:space-y-6 lg:shrink-0 lg:sticky top-0 lg:h-screen lg:w-[240px] xl:w-[298px] lg:pt-28">
                        <h5>Table of Contents</h5>

                        <div id="tableOfContents" className="flex flex-col gap-y-2 body-sm opacity-50 mt-[21px] *:w-fit">
                            {domains.map((item, i) => (
                                <a key={i}
                                    href={`#${getId(item.title)}`}
                                    className={`${activeSection === getId(item.title) && "text-[--blue-primary] font-semibold"} hover:underline`}
                                >
                                    {item.title}
                                </a>
                            ))}

                            <a href="#conclusion" className={`${activeSection === "conclusion" && "text-[--blue-primary] font-semibold"} hover:underline`}>
                                Conclusion
                            </a>
                        </div>
                    </div>



                    {/* Content */}
                    <div id="blogContent" className="space-y-6 sm:space-y-10 body-lg">

                        <h6 className="blog-card-highlight">In our <Link to="/blogs/ai-evolution-from-chatbots-to-super-intelligence" className='underline'> previous blog</Link>, we explored the basic concepts of AI and how it's already having a huge impact on our society. But AI doesn’t stop there—it’s revolutionizing entire industries, making tasks easier, faster, and more efficient. In this blog, we’ll dive deeper into how AI is being applied in various domains and transforming the way we live and work.</h6>



                        {/* Domains of AI */}
                        {domains.map((domain, i) => (
                            <div key={i} className="space-y-3 sm:space-y-6">
                                <h2 id={getId(domain.title)}>{domain.title}</h2>


                                <div className="space-y-2 max-sm:py-3">
                                    <img src={domain.image} alt={domain.title} title={domain.title} className="rounded-[--round] mx-auto" />
                                    <p className="pt-2.5 border-t text-xs text-[#23232666] text-center">
                                        Image taken from Freepik
                                    </p>
                                </div>


                                <p dangerouslySetInnerHTML={{ __html: domain.textIntro }} />


                                <h6>Examples</h6>
                                <ul className="list-decimal list-inside space-y-1">
                                    {domain.bullets.map((example, j) => (
                                        <li key={j} dangerouslySetInnerHTML={{ __html: example }} />
                                    ))}
                                </ul>


                                <p dangerouslySetInnerHTML={{ __html: domain.text }} />
                            </div>
                        ))}



                        {/* CTA */}
                        <div className="p-5 sm:p-8 md:p-10 flex flex-col gap-y-6 bg-[#EAEBF3] rounded-[--round]">
                            <h3>Ready to Harness the Power of AI?</h3>
                            <h5>
                                AI isn’t just transforming industries—it’s creating new possibilities in every aspect of life. From creativity with Generative AI to life-saving solutions with Expert Systems, the future is exciting and full of potential. Want to stay ahead of the curve and explore how AI can benefit your business or project?
                                <br />
                                <br />
                                <b>Let’s talk!</b> Contact us today to discover how we can help you integrate AI solutions tailored to your needs. Don’t miss out—be part of the AI revolution!
                            </h5>
                            <Link to="/contact-us" className="btn btn-black self-end text-center">Let’s Discuss</Link>
                        </div>



                        {/* Conclusion */}
                        <div className="space-y-3 sm:space-y-6">
                            <h2 id="conclusion">
                                Conclusion: The Future is Now—Are You Ready?
                            </h2>
                            <p>
                                AI is no longer a futuristic concept; it's a reality that’s reshaping our world in ways we couldn’t have imagined. From machines creating art to robots performing life-saving tasks, the possibilities are endless. But here’s the exciting part: this is just the beginning.
                                <br />
                                <br />
                                What could AI do for you? Whether you're in business, healthcare, education, or any other field, there’s a world of opportunity waiting to be explored. AI isn’t just a tool—it’s a revolution. The real question is, are you ready to be a part of it?
                                <br />
                                <br />
                                <b>Take the first step today!</b> Reach out to us, and let’s explore how AI can transform your vision into reality. Don’t get left behind—join the AI revolution now!
                            </p>
                        </div>



                        {/* Outro */}
                        <div className="p-5 sm:p-8 md:px-20 xl:py-12 space-y-6 bg-[--blue-primary] text-white rounded-[--round]">
                            <div className="flex items-center gap-x-2 lg:gap-x-4">
                                <img className='size-[52px] rounded-full' src={aiBlogAvatar} alt="avatar" />
                                <div>
                                    <h5>Faheela Waseem</h5>
                                    <div className="text-xs">Frontend Developer at Techieonix</div>
                                </div>
                            </div>



                            <div className="space-y-3">
                                <h4>AI Based on Application Domains</h4>


                                <div className="flex gap-x-5 text-xs">
                                    <p>October 25, 2024</p>
                                    <div className="flex items-center gap-x-1 px-2">
                                        <ClockBlog fill="white" />
                                        <span>{readTime}</span>
                                    </div>
                                </div>

                                <span className="chip border-white">Artificial Intelligence</span>
                            </div>


                            <div className="space-y-2">
                                <p className="font-semibold">Share Link</p>
                                <div className="flex max-lg:flex-wrap gap-2.5 *:p-2 *:border *:border-white">
                                    <ShareSocial white={true} setPopup={setPopup} title="AI Based on Application Domains" />
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* Share */}
                    <div className="max-lg:hidden shrink-0 sticky top-0 h-screen w-20 xl:w-[107px] pt-28 space-y-6">
                        <h5 className="text-end">Share</h5>
                        <div className="flex flex-col gap-y-2.5 items-end justify-end *:p-2 *:border *:border-[--black-primary]">
                            <ShareSocial setPopup={setPopup} title="AI Based on Application Domains" />
                        </div>
                    </div>
                </div>
            </section>




            {/* All Blogs */}
            <LatestBlogs />

            {/* CTA */}
            <BlogCta />

            {/* Contact Us Form */}
            <ContactUsForm />

            {/* Footer */}
            <Footer />

            {/* Popup */}
            {popup.show && <Popup state={popup.state} message={popup.message} closePopup={() => setPopup({ ...popup, show: false })} />}
        </>
    )
}