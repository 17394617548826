import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import { GoToTop, MetaDecorator } from './Components';
import { CaseStudies, ContactUs, Home, Service, Services, Blogs, CaseStudy, PrivacyPolicy, Blog, AIEvolution, AIApplicationDomainsBlog, AboutUs, Careers, MernStackBlog, PretrainedAIModels } from './Pages';

const imageBaseUrl = process.env.REACT_APP_TECHIEONIX_META_ASSET_URL;
const assetSasToken = process.env.REACT_APP_TECHIEONIX_META_ASSET_SAS_TOKEN;


const App = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  // const [imageUrl, setImageUrl] = useState("");
  const [url, setUrl] = useState("");
  const [type, setType] = useState("");

  const location = useLocation();

  // Construct image (techieonix meta image) base url
  const imageUrl = `${imageBaseUrl}?${assetSasToken}`;


  useEffect(() => {
    const page = window.location.pathname.split("/").slice(1)
      .map(el => el.replace(/-/g, " "))
      .map(el => el.slice(0, 1).toUpperCase() + el.slice(1));

    // Setting meta data for each page except blog
    if (page.length < 2 || page[0] !== "Blogs") {
      page[0] === "" ? setTitle("Techieonix International | Software Development Company specializing in AI, Cloud & DevOps") : setTitle(`${page.reverse().join(" | ")} | Techieonix International`);
      setType("website");
      // setImageUrl(techieonixLogo);
    }
    setDescription("Whether you're looking to build a new website, develop a mobile app, or migrate your systems to the cloud, we can help. We take pride in delivering high-quality solutions that meet your unique needs and exceed your expectations. So, if you're looking for a team of technology experts who can help you achieve your goals, look no further than our team at @Techieonix.");
    setUrl(window.location.href);


    // Redirectin to desired section if url has `#` in it
    const hash = window.location.hash;
    if (hash !== "" || hash) {
      setTimeout(() => {
        const element = document.getElementById(hash.substring(1));
        element && element.scrollIntoView();
      }, 100);
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);


  return (
    <>
      <MetaDecorator
        title={title}
        description={description}
        imageUrl={imageUrl}
        type={type}
        url={url}
      />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Services */}
        <Route path="/services" element={<Services />} />
        <Route path="/services/:serviceParam" element={<Service />} />

        {/* Case studies */}
        <Route path="/case-studies" element={<CaseStudies />} />
        <Route path="/case-studies/:caseStudyParam" element={<CaseStudy />} />

        {/* Blogs */}
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:blogParam" element={<Blog />} />
        <Route path="/blogs/ai-evolution-from-chatbots-to-super-intelligence" element={<AIEvolution />} />
        <Route path="/blogs/discovering-the-mern-stack-a-starters-route-to-full-stack-javaScript" element={<MernStackBlog />} />
        <Route path="/blogs/ai-based-on-application-domains" element={<AIApplicationDomainsBlog />} />
        <Route path="/blogs/leveraging-pretrained-ai-models-and-frameworks" element={<PretrainedAIModels />} />
      </Routes>
    </>
  );
};


const AppWrapper = () => {
  return (
    <BrowserRouter>
      <App />
      <GoToTop />
    </BrowserRouter>
  );
};

export default AppWrapper;