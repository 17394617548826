import React, { useEffect, useRef } from 'react';
import Marquee from "react-fast-marquee";

import { clientSkPrinting, clientDerma, clientACloudTechie, clientApkiKhidmat, clientHomeHeartConstruction, clientMasterBangles, clientMba, clientBgVideo } from "../assets";


export const OurClients = () => {
    const canvasRef = useRef(null);
    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        video.addEventListener('play', () => {
            const draw = () => {
                if (!video.paused && !video.ended) {
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                    requestAnimationFrame(draw);
                }
            };
            draw();
        });

        // Adjust canvas size if the window is resized
        const handleResize = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <section className="bg-[--blue-primary] md:bg-[#2442e466] relative theme-section">
            <div className={`max-md:hidden w-full absolute top-0 left-0 h-full overflow-hidden rounded-[--round] -z-10 ${false && "hidden"}`}>
                <canvas ref={canvasRef} className="hidden"></canvas>
                <video ref={videoRef} src={clientBgVideo} autoPlay loop muted className='w-full' ></video>
            </div>


            <div className="px-5 md:px-10 xl:px-[--px-xl] py-10 lg:py-[--py-lg] xl:py-20 space-y-10 center">
                <div className="space-y-6 md:px-10 xl:px-0 xl:w-[--title-intro-width] mx-auto text-center text-white !mt-0">
                    <h1>Our Clients</h1>
                    <p>We are proud to have worked with a diverse range of clients from different industries. Our portfolio includes startups, SMEs, and large enterprises, each benefitting from our tailored solutions.</p>
                </div>

                <div className="bg-white rounded-[--round] px-10 md:px-8 lg:px-10 py-2.5 md:py-6">
                    <Marquee>
                        <div className="flex *:ml-10 *:md:ml-20">
                            <img src={clientSkPrinting} alt="SK Printing Solution" title="SK Printing Solution" className="h-[--py-lg] lg:h-20 w-auto mx-auto" />
                            <img src={clientDerma} alt="Four Season Derma" title="Four Season Derma" className="h-[--py-lg] lg:h-20 w-auto mx-auto" />
                            <img src={clientACloudTechie} alt="A Cloud Techie" title="A Cloud Techie" className="h-[--py-lg] lg:h-20 w-auto mx-auto" />
                            <img src={clientApkiKhidmat} alt="Apki Khidmat" title="Apki Khidmat" className="h-[--py-lg] lg:h-20 w-auto mx-auto" />
                            <img src={clientHomeHeartConstruction} alt="Home Heart Construction" title="Home Heart Construction" className="h-[--py-lg] lg:h-20 w-auto mx-auto" />
                            <img src={clientMasterBangles} alt="Master Bangles" title="Master Bangles" className="h-[--py-lg] lg:h-20 w-auto mx-auto" />
                            <img src={clientMba} alt="MBA Consultants" title="MBA Consultants" className="h-[--py-lg] lg:h-20 w-auto mx-auto" />
                        </div>
                    </Marquee>
                </div>
            </div>
        </section>
    )
};