import React from 'react';

import { techBackendDjango, techBackendDotnet, techBackendExpress, techBackendNextjs, techBackendNodejs, techBackendSpringboot, techCloudOpenshift, techCloudAmazonWebServices, techCloudAzure, techCloudGoogleCloud, techDbCosmosdb, techDbFirebaseRealtimeDatabase, techDbGoogleBigquery, techDbMicrosoftSqlServer, techDbMongodb, techDbMysql, techDbPostgresql, techDevopsAnsible, techDevopsApplicationInsights, techDevopsArm, techDevopsBicep, techDevopsAzureDevops, techDevopsGithubActions, techDevopsGitlab, techDevopsJenkins, techDevopsNagios, techDevopsNewrelic, techDevopsSplunk, techDevopsTerraform, techDevopsYaml, techDevopsZabbix, techFrontendBootstrap, techFrontendCss, techFrontendHtml, techFrontendJavascript, techFrontendAngular, techFrontendReact, techFrontendTailwind, techFrontendVue, techPlatformVercel, techPlatformLambda, techPlatformElasticBeanstalk, techPlatformAzureAppServices, techPlatformDocker, techPlatformHeroku, techPlatformKubernetes, techPlatformNetlify, techPlatformServerless, techPlatformWordpress, techQaApacheJmeter, techQaJunit, techQaPostman, techQaSelenium, techUiuxXd, techUiuxFigma, techUiuxSketch, techUiuxWebflow, techPlatformGithub, techProjectConfluence, techProjectJira, techProjectSharepoint, techProjectPlanner, techScriptingPython, techScriptingBash, techScriptingPowershell } from "../assets";


const techStack = [
    {
        category: "Front End",
        technology: [
            {
                name: "Angular",
                logo: techFrontendAngular
            },
            {
                name: "React",
                logo: techFrontendReact
            },
            {
                name: "Vue.js",
                logo: techFrontendVue
            },
            {
                name: "Html5",
                logo: techFrontendHtml
            },
            {
                name: "CSS3",
                logo: techFrontendCss
            },
            {
                name: "JavaScript",
                logo: techFrontendJavascript
            },
            {
                name: "Bootstrap",
                logo: techFrontendBootstrap
            },
            {
                name: "TailwindCSS",
                logo: techFrontendTailwind
            }
        ]
    },
    {
        category: "Back End",
        technology: [
            {
                name: "Node.js",
                logo: techBackendNodejs
            },
            {
                name: "Django",
                logo: techBackendDjango
            },
            {
                name: "Spring Boot",
                logo: techBackendSpringboot
            },
            {
                name: "Express.js",
                logo: techBackendExpress
            },
            {
                name: "ASP.NET",
                logo: techBackendDotnet
            },
            {
                name: "Next.JS",
                logo: techBackendNextjs
            }
        ]
    },
    {
        category: "Database",
        technology: [
            {
                name: "MySQL",
                logo: techDbMysql
            },
            {
                name: "PostgreSQL",
                logo: techDbPostgresql
            },
            {
                name: "MongoDB",
                logo: techDbMongodb
            },
            {
                name: "CosmosDB",
                logo: techDbCosmosdb
            },
            {
                name: "Microsoft SQL Server",
                logo: techDbMicrosoftSqlServer
            },
            {
                name: "Firebase Realtime Database",
                logo: techDbFirebaseRealtimeDatabase
            },
            {
                name: "Google Big Query",
                logo: techDbGoogleBigquery
            }
        ]
    },
    {
        category: "Platform",
        technology: [
            {
                name: "Docker",
                logo: techPlatformDocker
            },
            {
                name: "Kubernetes",
                logo: techPlatformKubernetes
            },
            {
                name: "Heroku",
                logo: techPlatformHeroku
            },
            {
                name: "AWS Elastic Beanstalk",
                logo: techPlatformElasticBeanstalk
            },
            {
                name: "Azure App Service",
                logo: techPlatformAzureAppServices
            },
            {
                name: "Lambda Functions",
                logo: techPlatformLambda
            },
            {
                name: "Serverless",
                logo: techPlatformServerless
            },
            {
                name: "Vercel",
                logo: techPlatformVercel
            },
            {
                name: "Netlify",
                logo: techPlatformNetlify
            },
            {
                name: "WordPress",
                logo: techPlatformWordpress
            },
            {
                name: "Github",
                logo: techPlatformGithub
            }
        ]
    },
    {
        category: "Devops",
        technology: [
            {
                name: "Jenkins",
                logo: techDevopsJenkins
            },
            {
                name: "Terraform (IaC)",
                logo: techDevopsTerraform
            },
            {
                name: "ARM Templates (IaC)",
                logo: techDevopsArm
            },
            {
                name: "Bicep Templates",
                logo: techDevopsBicep
            },
            {
                name: "Ansible",
                logo: techDevopsAnsible
            },
            {
                name: "GitLab CICD",
                logo: techDevopsGitlab
            },
            {
                name: "Azure DevOps CICD",
                logo: techDevopsAzureDevops
            },
            {
                name: "GitHub Actions",
                logo: techDevopsGithubActions
            },
            {
                name: "Nagios",
                logo: techDevopsNagios
            },
            {
                name: "Zabbix",
                logo: techDevopsZabbix
            },
            {
                name: "Splunk",
                logo: techDevopsSplunk
            },
            {
                name: "NewRelic",
                logo: techDevopsNewrelic
            },
            {
                name: "Application Insights",
                logo: techDevopsApplicationInsights
            },
            {
                name: "YAML",
                logo: techDevopsYaml
            }
        ]
    },
    {
        category: "Cloud",
        technology: [
            {
                name: "AWS",
                logo: techCloudAmazonWebServices
            },
            {
                name: "Microsoft Azure",
                logo: techCloudAzure
            },
            {
                name: "Google Cloud",
                logo: techCloudGoogleCloud
            },
            {
                name: "OpenShift",
                logo: techCloudOpenshift
            }
        ]
    },
    {
        category: "Project Management",
        technology: [
            {
                name: "JIRA",
                logo: techProjectJira
            },
            {
                name: "Confluence",
                logo: techProjectConfluence
            },
            {
                name: "Microsoft SharePoint",
                logo: techProjectSharepoint
            },
            {
                name: "Microsoft Planner",
                logo: techProjectPlanner
            }
        ]
    },
    {
        category: "UI/UX",
        technology: [
            {
                name: "Figma",
                logo: techUiuxFigma
            },
            {
                name: "WebFlow",
                logo: techUiuxWebflow
            },
            {
                name: "Sketch",
                logo: techUiuxSketch
            },
            {
                name: "Adobe XD",
                logo: techUiuxXd
            }
        ]
    },
    {
        category: "Scripting",
        technology: [
            {
                name: "JavaScript",
                logo: techFrontendJavascript
            },
            {
                name: "Python",
                logo: techScriptingPython
            },
            {
                name: "Bash",
                logo: techScriptingBash
            },
            {
                name: "PowerShell",
                logo: techScriptingPowershell
            }
        ]
    },
    {
        category: "QA and Testing",
        technology: [
            {
                name: "Selenium",
                logo: techQaSelenium
            },
            {
                name: "JUnit",
                logo: techQaJunit
            },
            {
                name: "Postman",
                logo: techQaPostman
            },
            {
                name: "Apache JMeter",
                logo: techQaApacheJmeter
            }
        ]
    },
];


export const TechStack = ({ isServicePage = true }) => {
    return (
        <section className="px-5 md:p-10 lg:px-10 xl:px-[--px-xl] py-10 lg:py-[--py-lg] xl:py-20 space-y-10 center">

            <h1 className="xl:w-[--title-intro-width] text-center mx-auto">
                {isServicePage ?
                    "Crafting Solutions with Cutting-Edge Development & Design Tools" :
                    "Technologies We Love to Talk About"
                }
            </h1>



            {/* Tech Stack */}
            <div className="max-lg:space-y-6 md:grid grid-cols-2 xl:grid-cols-3 gap-10">
                {techStack.map((tech, i) => (
                    <div key={i} className={`space-y-6 ${i === techStack.length - 1 && "xl:col-span-3"}`}>
                        <h4>{tech.category}</h4>
                        <div className="flex flex-wrap gap-3">
                            {tech.technology?.map((item) => (
                                <div key={item.name} className="px-3 lg:px-6 py-1.5 flex gap-x-2 items-center rounded-[--round] bg-[--white-primary]">
                                    <img src={item.logo} alt={item.name} title={item.name} className="size-6 lg:size-8" />
                                    <span>{item.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>


        </section>
    )
}