import img_arrowFull from "./arrow-full.svg";
import img_arrow from "./arrow.svg";
import img_aboutValue1 from "./about-value-1.png"
import img_aboutValue2 from "./about-value-2.png"
import img_aboutValue3 from "./about-value-3.png"
import img_aboutValue4 from "./about-value-4.png"
import img_careerLifeBalance from "./career-life-balance.svg"
import img_careerLifeCollaborative from "./career-life-collaborative.svg"
import img_careerLifeHealth from "./career-life-health.svg"
import img_careerLifeLearning from "./career-life-learning.svg"
import img_careerLifeRecognition from "./career-life-recognition.svg"
import img_clientACloudTechie from "./client-acloudtechie.png";
import img_clientApkiKhidmat from "./client-apkikhidmat.png";
import img_clientBgVideo from "./client-bg-video.mp4";
import img_clientDerma from "./client-derma.png";
import img_clientHomeHeartConstruction from "./client-home-heart-construction.png";
import img_clientMasterBangles from "./client-master-bangles.png";
import img_clientMba from "./client-mba.png";
import img_clientSkPrinting from "./client-sk-printing.png";
import img_clipboard from "./clipboard.svg"
import img_dash from "./dash.svg";
import img_developerAnas from "./developer-anas.jpg"
import img_email from "./email.svg";
import img_error from "./error.svg";
import img_key1 from "./key-1.svg";
import img_key2 from "./key-2.svg";
import img_key3 from "./key-3.svg";
import img_key4 from "./key-4.svg";
import img_key5 from "./key-5.svg";
import img_location from "./location.svg";
import img_logoFooter from "./logo-footer.svg";
import img_logoWhite from "./logo-white.svg";
import img_logo from "./logo.svg";
import img_navClose from "./nav-close.svg";
import img_navHamburger from "./nav-hamburger.svg";
import img_phone from "./phone.svg";
import img_techieonixLogo from './techieonix-logo.png'
import img_testimonialAcloudTechie from "./testimonial-acloudTechie.png";
import img_testimonialHomeHeart from "./testimonial-homeHeart.png";
import img_testimonialMba from "./testimonial-mba.png";
import img_testimonialSk from "./testimonial-sk.png";



/// Tech Stack
import img_techBackendDjango from "./tech-stack/tech-backend-django.svg";
import img_techBackendDotnet from "./tech-stack/tech-backend-dotnet.svg";
import img_techBackendExpress from "./tech-stack/tech-backend-express.svg";
import img_techBackendNextjs from "./tech-stack/tech-backend-nextjs.svg";
import img_techBackendNodejs from "./tech-stack/tech-backend-nodejs.svg";
import img_techBackendSpringboot from "./tech-stack/tech-backend-springboot.svg";
import img_techCloudAmazonWebServices from "./tech-stack/tech-cloud-amazon-web-services.svg";
import img_techCloudAzure from "./tech-stack/tech-cloud-azure.svg";
import img_techCloudGoogleCloud from "./tech-stack/tech-cloud-google-cloud.svg";
import img_techCloudOpenshift from "./tech-stack/tech-cloud-openshift.svg";
import img_techDbCosmosdb from "./tech-stack/tech-db-cosmosdb.svg";
import img_techDbFirebaseRealtimeDatabase from "./tech-stack/tech-db-firebase-realtime-database.svg";
import img_techDbGoogleBigquery from "./tech-stack/tech-db-google-bigquery.svg";
import img_techDbMicrosoftSqlServer from "./tech-stack/tech-db-microsoft-sql-server.svg";
import img_techDbMongodb from "./tech-stack/tech-db-mongodb.svg";
import img_techDbMysql from "./tech-stack/tech-db-mysql.svg";
import img_techDbPostgresql from "./tech-stack/tech-db-postgresql.svg";
import img_techDevopsAnsible from "./tech-stack/tech-devops-ansible.svg";
import img_techDevopsApplicationInsights from "./tech-stack/tech-devops-application-insights.svg";
import img_techDevopsArm from "./tech-stack/tech-devops-arm.svg";
import img_techDevopsAzureDevops from "./tech-stack/tech-devops-azure-devops.svg";
import img_techDevopsBicep from "./tech-stack/tech-devops-bicep.svg";
import img_techDevopsGithubActions from "./tech-stack/tech-devops-github-actions.svg";
import img_techDevopsGitlab from "./tech-stack/tech-devops-gitlab.svg";
import img_techDevopsJenkins from "./tech-stack/tech-devops-jenkins.svg";
import img_techDevopsNagios from "./tech-stack/tech-devops-nagios.svg";
import img_techDevopsNewrelic from "./tech-stack/tech-devops-newrelic.svg";
import img_techDevopsSplunk from "./tech-stack/tech-devops-splunk.svg";
import img_techDevopsTerraform from "./tech-stack/tech-devops-terraform.svg";
import img_techDevopsYaml from "./tech-stack/tech-devops-yaml.svg";
import img_techDevopsZabbix from "./tech-stack/tech-devops-zabbix.svg";
import img_techFrontendAngular from "./tech-stack/tech-frontend-angular.svg";
import img_techFrontendBootstrap from "./tech-stack/tech-frontend-bootstrap.svg";
import img_techFrontendCss from "./tech-stack/tech-frontend-css.svg";
import img_techFrontendHtml from "./tech-stack/tech-frontend-html.svg";
import img_techFrontendJavascript from "./tech-stack/tech-frontend-javascript.svg";
import img_techFrontendReact from "./tech-stack/tech-frontend-react.svg";
import img_techFrontendTailwind from "./tech-stack/tech-frontend-tailwind.svg";
import img_techFrontendVue from "./tech-stack/tech-frontend-vue.svg";
import img_techPlatformAzureAppServices from "./tech-stack/tech-platform-azure-app-services.svg";
import img_techPlatformDocker from "./tech-stack/tech-platform-docker.svg";
import img_techPlatformElasticBeanstalk from "./tech-stack/tech-platform-elastic-beanstalk.svg";
import img_techPlatformGithub from "./tech-stack/tech-platform-github.svg";
import img_techPlatformHeroku from "./tech-stack/tech-platform-heroku.svg";
import img_techPlatformKubernetes from "./tech-stack/tech-platform-kubernetes.svg";
import img_techPlatformLambda from "./tech-stack/tech-platform-lambda.svg";
import img_techPlatformNetlify from "./tech-stack/tech-platform-netlify.svg";
import img_techPlatformServerless from "./tech-stack/tech-platform-serverless.svg";
import img_techPlatformVercel from "./tech-stack/tech-platform-vercel.svg";
import img_techPlatformWordpress from "./tech-stack/tech-platform-wordpress.svg";
import img_techProjectConfluence from "./tech-stack/tech-project-confluence.svg";
import img_techProjectJira from "./tech-stack/tech-project-jira.svg";
import img_techProjectPlanner from "./tech-stack/tech-project-planner.svg";
import img_techProjectSharepoint from "./tech-stack/tech-project-sharepoint.svg";
import img_techQaApacheJmeter from "./tech-stack/tech-qa-apache-jmeter.svg";
import img_techQaJunit from "./tech-stack/tech-qa-junit.svg";
import img_techQaPostman from "./tech-stack/tech-qa-postman.svg";
import img_techQaSelenium from "./tech-stack/tech-qa-selenium.svg";
import img_techScriptingBash from "./tech-stack/tech-scripting-bash.svg"
import img_techScriptingPowershell from "./tech-stack/tech-scripting-powershell.svg"
import img_techScriptingPython from "./tech-stack/tech-scripting-python.svg"
import img_techUiuxFigma from "./tech-stack/tech-uiux-figma.svg";
import img_techUiuxSketch from "./tech-stack/tech-uiux-sketch.svg";
import img_techUiuxWebflow from "./tech-stack/tech-uiux-webflow.svg";
import img_techUiuxXd from "./tech-stack/tech-uiux-xd.svg";



/// Services
import img_service1 from "./services/service-1.jpg";
import img_service2 from "./services/service-2.jpg";
import img_service3 from "./services/service-3.jpg";
import img_service4 from "./services/service-4.jpg";
import img_service5 from "./services/service-5.jpg";
import img_service6 from "./services/service-6.jpg";
import img_service7 from "./services/service-7.jpg";
import img_service8 from "./services/service-8.jpg";
import img_serviceCardBg1 from "./services/service-card-bg-1.png";
import img_serviceCardBg2 from "./services/service-card-bg-2.png";
import img_serviceCardBg3 from "./services/service-card-bg-3.png";
import img_serviceCardBg4 from "./services/service-card-bg-4.png";
import img_serviceCardBg5 from "./services/service-card-bg-5.png";
import img_serviceCardBg6 from "./services/service-card-bg-6.png";
import img_serviceCardBg7 from "./services/service-card-bg-7.png";
import img_serviceCardBg8 from "./services/service-card-bg-8.png";
import img_serviceIcon1 from "./services/service-icon-1.svg";
import img_serviceIcon2 from "./services/service-icon-2.svg";
import img_serviceIcon3 from "./services/service-icon-3.svg";
import img_serviceIcon4 from "./services/service-icon-4.svg";
import img_serviceIcon5 from "./services/service-icon-5.svg";
import img_serviceIcon6 from "./services/service-icon-6.svg";
import img_serviceIcon7 from "./services/service-icon-7.svg";
import img_serviceIcon8 from "./services/service-icon-8.svg";
import img_serviceKeyFeaturesBg1 from "./services/service-keyFeatures-bg-1.jpg";
import img_serviceKeyFeaturesBg2 from "./services/service-keyFeatures-bg-2.jpg";
import img_serviceKeyFeaturesBg3 from "./services/service-keyFeatures-bg-3.jpg";
import img_serviceKeyFeaturesBg4 from "./services/service-keyFeatures-bg-4.jpg";
import img_serviceKeyFeaturesBg5 from "./services/service-keyFeatures-bg-5.jpg";
import img_serviceKeyFeaturesBg6 from "./services/service-keyFeatures-bg-6.jpg";
import img_serviceKeyFeaturesBg7 from "./services/service-keyFeatures-bg-7.jpg";
import img_serviceKeyFeaturesBg8 from "./services/service-keyFeatures-bg-8.jpg";
import img_servicesWhyUsBg1 from "./services/services-why-us-bg-1.png";
import img_servicesWhyUsBg2 from "./services/services-why-us-bg-2.png";
import img_servicesWhyUsBg3 from "./services/services-why-us-bg-3.png";
import img_servicesWhyUsBg4 from "./services/services-why-us-bg-4.png";
import img_servicesWhyUsBg5 from "./services/services-why-us-bg-5.png";


/// services/Service Key Points Icons
import img_serviceAppDev1 from "./services/key-points/service-app-dev-2.svg";
import img_serviceAppDev2 from "./services/key-points/service-app-dev-2.svg";
import img_serviceAppDev3 from "./services/key-points/service-app-dev-3.svg";
import img_serviceAppDev4 from "./services/key-points/service-app-dev-4.svg";
import img_serviceAppDev5 from "./services/key-points/service-app-dev-5.svg";
import img_serviceCloud1 from "./services/key-points/service-cloud-1.svg";
import img_serviceCloud2 from "./services/key-points/service-cloud-2.svg";
import img_serviceCloud3 from "./services/key-points/service-cloud-3.svg";
import img_serviceCloud4 from "./services/key-points/service-cloud-4.svg";
import img_serviceCloud5 from "./services/key-points/service-cloud-5.svg";
import img_serviceCloud6 from "./services/key-points/service-cloud-6.svg";
import img_serviceCloud7 from "./services/key-points/service-cloud-7.svg";
import img_serviceCloud8 from "./services/key-points/service-cloud-8.svg";
import img_serviceDevops1 from "./services/key-points/service-devops-1.svg";
import img_serviceDevops2 from "./services/key-points/service-devops-2.svg";
import img_serviceDevops3 from "./services/key-points/service-devops-3.svg";
import img_serviceDevops4 from "./services/key-points/service-devops-4.svg";
import img_serviceDevops5 from "./services/key-points/service-devops-5.svg";
import img_serviceDevops6 from "./services/key-points/service-devops-6.svg";
import img_serviceDevops7 from "./services/key-points/service-devops-7.svg";
import img_serviceDevops8 from "./services/key-points/service-devops-8.svg";
import img_serviceDevops9 from "./services/key-points/service-devops-9.svg";
import img_serviceDevops10 from "./services/key-points/service-devops-10.svg";
import img_serviceIt1 from "./services/key-points/service-it-1.svg";
import img_serviceIt2 from "./services/key-points/service-it-2.svg";
import img_serviceIt3 from "./services/key-points/service-it-3.svg";
import img_serviceIt4 from "./services/key-points/service-it-4.svg";
import img_serviceIt5 from "./services/key-points/service-it-5.svg";
import img_serviceIt6 from "./services/key-points/service-it-6.svg";
import img_serviceIt7 from "./services/key-points/service-it-7.svg";
import img_serviceIt8 from "./services/key-points/service-it-8.svg";
import img_serviceQa1 from "./services/key-points/service-qa-1.svg";
import img_serviceQa2 from "./services/key-points/service-qa-2.svg";
import img_serviceQa3 from "./services/key-points/service-qa-3.svg";
import img_serviceQa4 from "./services/key-points/service-qa-4.svg";
import img_serviceQa5 from "./services/key-points/service-qa-5.svg";
import img_serviceQa6 from "./services/key-points/service-qa-6.svg";
import img_serviceQa7 from "./services/key-points/service-qa-7.svg";
import img_serviceQa8 from "./services/key-points/service-qa-8.svg";
import img_serviceQa9 from "./services/key-points/service-qa-9.svg";
import img_serviceQa10 from "./services/key-points/service-qa-10.svg";
import img_serviceSoftware1 from "./services/key-points/service-software-1.svg";
import img_serviceSoftware2 from "./services/key-points/service-software-2.svg";
import img_serviceSoftware3 from "./services/key-points/service-software-3.svg";
import img_serviceSoftware4 from "./services/key-points/service-software-4.svg";
import img_serviceSoftware5 from "./services/key-points/service-software-5.svg";
import img_serviceSoftware6 from "./services/key-points/service-software-6.svg";
import img_serviceSoftware7 from "./services/key-points/service-software-7.svg";
import img_serviceUiux1 from "./services/key-points/service-uiux-1.svg";
import img_serviceUiux2 from "./services/key-points/service-uiux-2.svg";
import img_serviceUiux3 from "./services/key-points/service-uiux-3.svg";
import img_serviceUiux4 from "./services/key-points/service-uiux-4.svg";
import img_serviceUiux5 from "./services/key-points/service-uiux-5.svg";
import img_serviceUiux6 from "./services/key-points/service-uiux-6.svg";
import img_serviceUiux7 from "./services/key-points/service-uiux-7.svg";
import img_serviceUiux8 from "./services/key-points/service-uiux-8.svg";
import img_serviceWebDev1 from "./services/key-points/service-web-dev-1.svg";
import img_serviceWebDev2 from "./services/key-points/service-web-dev-2.svg";
import img_serviceWebDev3 from "./services/key-points/service-web-dev-3.svg";
import img_serviceWebDev4 from "./services/key-points/service-web-dev-4.svg";
import img_serviceWebDev5 from "./services/key-points/service-web-dev-5.svg";



/// Case Studies
import img_caseComponentEntraSign from "./case-studies/case-component-entraSign.jpg";
import img_caseComponentIms from "./case-studies/case-component-ims.jpg";
import img_caseComponentMba from "./case-studies/case-component-mba.png";
import img_caseComponentSk from "./case-studies/case-component-sk.jpg";
import img_caseEntraSignBg from "./case-studies/case-entraSign-bg.png";
import img_caseEntraSignPic from "./case-studies/case-entraSign-pic.png";
import img_caseEntraSignTechAutomation from "./case-studies/case-entraSign-tech-automation.svg";
import img_caseEntraSignTechLogicApps from "./case-studies/case-entraSign-tech-logicApps.svg";
import img_caseEntraSignTechMsEntraId from "./case-studies/case-entraSign-tech-msEntraId.svg";
import img_caseEntraSignTechPowershell from "./case-studies/case-entraSign-tech-powershell.svg";
import img_caseEntraSign from "./case-studies/case-entraSign.png";
import img_caseImsBg from "./case-studies/case-ims-bg.png";
import img_caseImsPic1 from "./case-studies/case-ims-pic-1.jpg";
import img_caseImsPic2 from "./case-studies/case-ims-pic-2.png";
import img_caseImsPic3 from "./case-studies/case-ims-pic-3.jpg";
import img_caseIms from "./case-studies/case-ims.png";
import img_caseMbaBg from "./case-studies/case-mba-bg.png";
import img_caseMbaKey1 from "./case-studies/case-mba-key-1.svg";
import img_caseMbaKey2 from "./case-studies/case-mba-key-2.svg";
import img_caseMbaKey3 from "./case-studies/case-mba-key-3.svg";
import img_caseMbaLogo from "./case-studies/case-mba-logo.svg";
import img_caseMbaPic1 from "./case-studies/case-mba-pic-1.png";
import img_caseMbaPic2 from "./case-studies/case-mba-pic-2.png";
import img_caseMbaPic3 from "./case-studies/case-mba-pic-3.png";
import img_caseMba from "./case-studies/case-mba.png";
import img_caseSkBg from "./case-studies/case-sk-bg.png";
import img_caseSkLogo from "./case-studies/case-sk-logo.svg";
import img_caseSkPic1 from "./case-studies/case-sk-pic-1.png";
import img_caseSkPic2 from "./case-studies/case-sk-pic-2.png";
import img_caseSkPic3 from "./case-studies/case-sk-pic-3.png";
import img_caseSkPic4 from "./case-studies/case-sk-pic-4.png";
import img_caseSkResult1 from "./case-studies/case-sk-result-1.svg";
import img_caseSkResult2 from "./case-studies/case-sk-result-2.svg";
import img_caseSkResult3 from "./case-studies/case-sk-result-3.svg";
import img_caseSkResult4 from "./case-studies/case-sk-result-4.svg";
import img_caseSk from "./case-studies/case-sk.png";


/// Blogs
import img_aiBlogAvatar from "./blogs/ai-blogs-avatar.jpg";
import img_blog1Dao from "./blogs/blog-1-dao.png";
import img_blog1Defi from "./blogs/blog-1-defi.png";
import img_blog1Dweb from "./blogs/blog-1-dweb.png";
import img_blog1Nft from "./blogs/blog-1-nft.png";
import img_blog1SmartContract from "./blogs/blog-1-smart-contract.png";
import img_blog1 from "./blogs/blog-1.jpg";
import img_blog2BusinessStrategy from "./blogs/blog-2-business-strategy.png";
import img_blog2 from "./blogs/blog-2.jpg";
import img_blog3Launch from "./blogs/blog-3-launch.png";
import img_blog3Solution from "./blogs/blog-3-solution.png";
import img_blog3 from "./blogs/blog-3.jpg";
import img_blog4 from "./blogs/blog-4.jpg";
import img_blog5Why from "./blogs/blog-5-why.png";
import img_blog5 from "./blogs/blog-5.jpg";
import img_blog6ComputerVision from "./blogs/blog-6-computer-vision.jpg";
import img_blog6Expert from "./blogs/blog-6-expert.jpg";
import img_blog6GenAi from "./blogs/blog-6-gen-ai.jpg";
import img_blog6Nlp from "./blogs/blog-6-nlp.jpg";
import img_blog6ReinforceLearn from "./blogs/blog-6-reinforce-learn.jpg";
import img_blog6Robotics from "./blogs/blog-6-robotics.jpg";
import img_blog6 from "./blogs/blog-6.jpg";
import img_blog7Reactive from "./blogs/blog-7-reactive.jpg";
import img_blog7ReinforceLearn from "./blogs/blog-7-reinforce-learn.jpg";
import img_blog7SelfAware from "./blogs/blog-7-self-aware.jpg";
import img_blog7Super from "./blogs/blog-7-super.jpg";
import img_blog7TheoryOfMind from "./blogs/blog-7-theory-of-mind.jpg";
import img_blog7ComputerVision from "./blogs/blog-7-computer-vision.jpg";
import img_blog7DeepLearn from "./blogs/blog-7-deep-learn.jpg";
import img_blog7General from "./blogs/blog-7-general.jpg";
import img_blog7LimitedMemory from "./blogs/blog-7-limited-memory.jpg";
import img_blog7MachineLearn from "./blogs/blog-7-machine-learn.jpg";
import img_blog7Narrow from "./blogs/blog-7-narrow.jpg";
import img_blog7Nlp from "./blogs/blog-7-nlp.jpg";
import img_blog7 from "./blogs/blog-7.jpg";
import img_blog8Express from "./blogs/blog-8-express.jpg";
import img_blog8Mongo from "./blogs/blog-8-mongo.jpg";
import img_blog8Node from "./blogs/blog-8-node.jpg";
import img_blog8React from "./blogs/blog-8-react.jpg";
import img_blog8 from "./blogs/blog-8.jpg";
import img_blog9 from "./blogs/blog-9.png";
import img_blog9Personalization from "./blogs/blog-9-personalization.jpg";
import img_blog9Recommendation from "./blogs/blog-9-recommendation.jpg";
import img_blog9Interactive from "./blogs/blog-9-interactive.jpg";
import img_blog9Analysis from "./blogs/blog-9-analysis.jpg";
import img_blog9Risk from "./blogs/blog-9-risk.jpg";
import img_blog9Fraud from "./blogs/blog-9-fraud.jpg";
import img_blog9Content from "./blogs/blog-9-content.jpg";
import img_blog9Image from "./blogs/blog-9-image.jpg";
import img_blog9nlp from "./blogs/blog-9-nlp.jpg";
import img_blog9Leverage from "./blogs/blog-9-leverage.jpg";
import img_blog9OpenAI from "./blogs/blog-9-openai.jpg";
import img_blog9Anthropic from "./blogs/blog-9-anthropic.PNG";
import img_blog9API from "./blogs/blog-9-api.jpg";
import img_blog9Frontend from "./blogs/blog-9-frontend.jpg";
import img_blog9Backend from "./blogs/blog-9-backend.jpg";
import img_blog9Stack from "./blogs/blog-9-stack.jpg";
import img_blog9UseCase from "./blogs/blog-9-usecase.jpg";











export const
  arrowFull = img_arrowFull,
  arrow = img_arrow,
  aboutValue1 = img_aboutValue1,
  aboutValue2 = img_aboutValue2,
  aboutValue3 = img_aboutValue3,
  aboutValue4 = img_aboutValue4,
  careerLifeBalance = img_careerLifeBalance,
  careerLifeCollaborative = img_careerLifeCollaborative,
  careerLifeHealth = img_careerLifeHealth,
  careerLifeLearning = img_careerLifeLearning,
  careerLifeRecognition = img_careerLifeRecognition,
  clientACloudTechie = img_clientACloudTechie,
  clientApkiKhidmat = img_clientApkiKhidmat,
  clientBgVideo = img_clientBgVideo,
  clientDerma = img_clientDerma,
  clientHomeHeartConstruction = img_clientHomeHeartConstruction,
  clientMasterBangles = img_clientMasterBangles,
  clientMba = img_clientMba,
  clientSkPrinting = img_clientSkPrinting,
  clipboard = img_clipboard,
  dash = img_dash,
  developerAnas = img_developerAnas,
  email = img_email,
  error = img_error,
  key1 = img_key1,
  key2 = img_key2,
  key3 = img_key3,
  key4 = img_key4,
  key5 = img_key5,
  location = img_location,
  logoFooter = img_logoFooter,
  logoWhite = img_logoWhite,
  logo = img_logo,
  navClose = img_navClose,
  navHamburger = img_navHamburger,
  phone = img_phone,
  techieonixLogo = img_techieonixLogo,
  testimonialAcloudTechie = img_testimonialAcloudTechie,
  testimonialHomeHeart = img_testimonialHomeHeart,
  testimonialMba = img_testimonialMba,
  testimonialSk = img_testimonialSk,




  // Tech Stack
  techBackendDjango = img_techBackendDjango,
  techBackendDotnet = img_techBackendDotnet,
  techBackendExpress = img_techBackendExpress,
  techBackendNextjs = img_techBackendNextjs,
  techBackendNodejs = img_techBackendNodejs,
  techBackendSpringboot = img_techBackendSpringboot,
  techCloudAmazonWebServices = img_techCloudAmazonWebServices,
  techCloudAzure = img_techCloudAzure,
  techCloudGoogleCloud = img_techCloudGoogleCloud,
  techCloudOpenshift = img_techCloudOpenshift,
  techDbCosmosdb = img_techDbCosmosdb,
  techDbFirebaseRealtimeDatabase = img_techDbFirebaseRealtimeDatabase,
  techDbGoogleBigquery = img_techDbGoogleBigquery,
  techDbMicrosoftSqlServer = img_techDbMicrosoftSqlServer,
  techDbMongodb = img_techDbMongodb,
  techDbMysql = img_techDbMysql,
  techDbPostgresql = img_techDbPostgresql,
  techDevopsAnsible = img_techDevopsAnsible,
  techDevopsApplicationInsights = img_techDevopsApplicationInsights,
  techDevopsArm = img_techDevopsArm,
  techDevopsAzureDevops = img_techDevopsAzureDevops,
  techDevopsBicep = img_techDevopsBicep,
  techDevopsGithubActions = img_techDevopsGithubActions,
  techDevopsGitlab = img_techDevopsGitlab,
  techDevopsJenkins = img_techDevopsJenkins,
  techDevopsNagios = img_techDevopsNagios,
  techDevopsNewrelic = img_techDevopsNewrelic,
  techDevopsSplunk = img_techDevopsSplunk,
  techDevopsTerraform = img_techDevopsTerraform,
  techDevopsYaml = img_techDevopsYaml,
  techDevopsZabbix = img_techDevopsZabbix,
  techFrontendAngular = img_techFrontendAngular,
  techFrontendBootstrap = img_techFrontendBootstrap,
  techFrontendCss = img_techFrontendCss,
  techFrontendHtml = img_techFrontendHtml,
  techFrontendJavascript = img_techFrontendJavascript,
  techFrontendReact = img_techFrontendReact,
  techFrontendTailwind = img_techFrontendTailwind,
  techFrontendVue = img_techFrontendVue,
  techPlatformAzureAppServices = img_techPlatformAzureAppServices,
  techPlatformDocker = img_techPlatformDocker,
  techPlatformElasticBeanstalk = img_techPlatformElasticBeanstalk,
  techPlatformGithub = img_techPlatformGithub,
  techPlatformHeroku = img_techPlatformHeroku,
  techPlatformKubernetes = img_techPlatformKubernetes,
  techPlatformLambda = img_techPlatformLambda,
  techPlatformNetlify = img_techPlatformNetlify,
  techPlatformServerless = img_techPlatformServerless,
  techPlatformVercel = img_techPlatformVercel,
  techPlatformWordpress = img_techPlatformWordpress,
  techProjectConfluence = img_techProjectConfluence,
  techProjectJira = img_techProjectJira,
  techProjectPlanner = img_techProjectPlanner,
  techProjectSharepoint = img_techProjectSharepoint,
  techQaApacheJmeter = img_techQaApacheJmeter,
  techQaJunit = img_techQaJunit,
  techQaPostman = img_techQaPostman,
  techQaSelenium = img_techQaSelenium,
  techScriptingBash = img_techScriptingBash,
  techScriptingPowershell = img_techScriptingPowershell,
  techScriptingPython = img_techScriptingPython,
  techUiuxFigma = img_techUiuxFigma,
  techUiuxSketch = img_techUiuxSketch,
  techUiuxWebflow = img_techUiuxWebflow,
  techUiuxXd = img_techUiuxXd,



  // Services
  service1 = img_service1,
  service2 = img_service2,
  service3 = img_service3,
  service4 = img_service4,
  service5 = img_service5,
  service6 = img_service6,
  service7 = img_service7,
  service8 = img_service8,
  serviceCardBg1 = img_serviceCardBg1,
  serviceCardBg2 = img_serviceCardBg2,
  serviceCardBg3 = img_serviceCardBg3,
  serviceCardBg4 = img_serviceCardBg4,
  serviceCardBg5 = img_serviceCardBg5,
  serviceCardBg6 = img_serviceCardBg6,
  serviceCardBg7 = img_serviceCardBg7,
  serviceCardBg8 = img_serviceCardBg8,
  serviceIcon1 = img_serviceIcon1,
  serviceIcon2 = img_serviceIcon2,
  serviceIcon3 = img_serviceIcon3,
  serviceIcon4 = img_serviceIcon4,
  serviceIcon5 = img_serviceIcon5,
  serviceIcon6 = img_serviceIcon6,
  serviceIcon7 = img_serviceIcon7,
  serviceIcon8 = img_serviceIcon8,
  serviceKeyFeaturesBg1 = img_serviceKeyFeaturesBg1,
  serviceKeyFeaturesBg2 = img_serviceKeyFeaturesBg2,
  serviceKeyFeaturesBg3 = img_serviceKeyFeaturesBg3,
  serviceKeyFeaturesBg4 = img_serviceKeyFeaturesBg4,
  serviceKeyFeaturesBg5 = img_serviceKeyFeaturesBg5,
  serviceKeyFeaturesBg6 = img_serviceKeyFeaturesBg6,
  serviceKeyFeaturesBg7 = img_serviceKeyFeaturesBg7,
  serviceKeyFeaturesBg8 = img_serviceKeyFeaturesBg8,
  servicesWhyUsBg1 = img_servicesWhyUsBg1,
  servicesWhyUsBg2 = img_servicesWhyUsBg2,
  servicesWhyUsBg3 = img_servicesWhyUsBg3,
  servicesWhyUsBg4 = img_servicesWhyUsBg4,
  servicesWhyUsBg5 = img_servicesWhyUsBg5,


  // Service --> Keypoints
  serviceAppDev1 = img_serviceAppDev1,
  serviceAppDev2 = img_serviceAppDev2,
  serviceAppDev3 = img_serviceAppDev3,
  serviceAppDev4 = img_serviceAppDev4,
  serviceAppDev5 = img_serviceAppDev5,
  serviceCloud1 = img_serviceCloud1,
  serviceCloud2 = img_serviceCloud2,
  serviceCloud3 = img_serviceCloud3,
  serviceCloud4 = img_serviceCloud4,
  serviceCloud5 = img_serviceCloud5,
  serviceCloud6 = img_serviceCloud6,
  serviceCloud7 = img_serviceCloud7,
  serviceCloud8 = img_serviceCloud8,
  serviceDevops1 = img_serviceDevops1,
  serviceDevops2 = img_serviceDevops2,
  serviceDevops3 = img_serviceDevops3,
  serviceDevops4 = img_serviceDevops4,
  serviceDevops5 = img_serviceDevops5,
  serviceDevops6 = img_serviceDevops6,
  serviceDevops7 = img_serviceDevops7,
  serviceDevops8 = img_serviceDevops8,
  serviceDevops9 = img_serviceDevops9,
  serviceDevops10 = img_serviceDevops10,
  serviceIt1 = img_serviceIt1,
  serviceIt2 = img_serviceIt2,
  serviceIt3 = img_serviceIt3,
  serviceIt4 = img_serviceIt4,
  serviceIt5 = img_serviceIt5,
  serviceIt6 = img_serviceIt6,
  serviceIt7 = img_serviceIt7,
  serviceIt8 = img_serviceIt8,
  serviceQa1 = img_serviceQa1,
  serviceQa2 = img_serviceQa2,
  serviceQa3 = img_serviceQa3,
  serviceQa4 = img_serviceQa4,
  serviceQa5 = img_serviceQa5,
  serviceQa6 = img_serviceQa6,
  serviceQa7 = img_serviceQa7,
  serviceQa8 = img_serviceQa8,
  serviceQa9 = img_serviceQa9,
  serviceQa10 = img_serviceQa10,
  serviceSoftware1 = img_serviceSoftware1,
  serviceSoftware2 = img_serviceSoftware2,
  serviceSoftware3 = img_serviceSoftware3,
  serviceSoftware4 = img_serviceSoftware4,
  serviceSoftware5 = img_serviceSoftware5,
  serviceSoftware6 = img_serviceSoftware6,
  serviceSoftware7 = img_serviceSoftware7,
  serviceUiux1 = img_serviceUiux1,
  serviceUiux2 = img_serviceUiux2,
  serviceUiux3 = img_serviceUiux3,
  serviceUiux4 = img_serviceUiux4,
  serviceUiux5 = img_serviceUiux5,
  serviceUiux6 = img_serviceUiux6,
  serviceUiux7 = img_serviceUiux7,
  serviceUiux8 = img_serviceUiux8,
  serviceWebDev1 = img_serviceWebDev1,
  serviceWebDev2 = img_serviceWebDev2,
  serviceWebDev3 = img_serviceWebDev3,
  serviceWebDev4 = img_serviceWebDev4,
  serviceWebDev5 = img_serviceWebDev5,



  // Blogs
  aiBlogAvatar = img_aiBlogAvatar,
  blog1Dao = img_blog1Dao,
  blog1Defi = img_blog1Defi,
  blog1Dweb = img_blog1Dweb,
  blog1Nft = img_blog1Nft,
  blog1SmartContract = img_blog1SmartContract,
  blog1 = img_blog1,
  blog2BusinessStrategy = img_blog2BusinessStrategy,
  blog2 = img_blog2,
  blog3Launch = img_blog3Launch,
  blog3Solution = img_blog3Solution,
  blog3 = img_blog3,
  blog4 = img_blog4,
  blog5Why = img_blog5Why,
  blog5 = img_blog5,
  blog6ComputerVision = img_blog6ComputerVision,
  blog6Expert = img_blog6Expert,
  blog6GenAi = img_blog6GenAi,
  blog6Nlp = img_blog6Nlp,
  blog6ReinforceLearn = img_blog6ReinforceLearn,
  blog6Robotics = img_blog6Robotics,
  blog6 = img_blog6,
  blog7Reactive = img_blog7Reactive,
  blog7ReinforceLearn = img_blog7ReinforceLearn,
  blog7SelfAware = img_blog7SelfAware,
  blog7Super = img_blog7Super,
  blog7TheoryOfMind = img_blog7TheoryOfMind,
  blog7ComputerVision = img_blog7ComputerVision,
  blog7DeepLearn = img_blog7DeepLearn,
  blog7General = img_blog7General,
  blog7LimitedMemory = img_blog7LimitedMemory,
  blog7MachineLearn = img_blog7MachineLearn,
  blog7Narrow = img_blog7Narrow,
  blog7Nlp = img_blog7Nlp,
  blog7 = img_blog7,
  blog8Express = img_blog8Express,
  blog8Mongo = img_blog8Mongo,
  blog8Node = img_blog8Node,
  blog8React = img_blog8React,
  blog8 = img_blog8,
  blog9 = img_blog9,
  blog9Personalization = img_blog9Personalization,
  blog9Recommendation = img_blog9Recommendation,
  blog9Interactive = img_blog9Interactive,
  blog9Analysis = img_blog9Analysis,
  blog9Risk = img_blog9Risk,
  blog9Fraud = img_blog9Fraud,
  blog9Content = img_blog9Content,
  blog9Image = img_blog9Image,
  blog9nlp = img_blog9nlp,
  blog9Leverage = img_blog9Leverage,
  blog9OpenAI = img_blog9OpenAI,
  blog9Anthropic = img_blog9Anthropic,
  blog9API = img_blog9API,
  blog9Frontend = img_blog9Frontend,
  blog9Backend = img_blog9Backend,
  blog9Stack = img_blog9Stack,
  blog9UseCase = img_blog9UseCase,




  // Case studies
  caseComponentEntraSign = img_caseComponentEntraSign,
  caseComponentIms = img_caseComponentIms,
  caseComponentMba = img_caseComponentMba,
  caseComponentSk = img_caseComponentSk,
  caseEntraSignBg = img_caseEntraSignBg,
  caseEntraSignPic = img_caseEntraSignPic,
  caseEntraSignTechAutomation = img_caseEntraSignTechAutomation,
  caseEntraSignTechLogicApps = img_caseEntraSignTechLogicApps,
  caseEntraSignTechMsEntraId = img_caseEntraSignTechMsEntraId,
  caseEntraSignTechPowershell = img_caseEntraSignTechPowershell,
  caseEntraSign = img_caseEntraSign,
  caseImsBg = img_caseImsBg,
  caseImsPic1 = img_caseImsPic1,
  caseImsPic2 = img_caseImsPic2,
  caseImsPic3 = img_caseImsPic3,
  caseIms = img_caseIms,
  caseMbaBg = img_caseMbaBg,
  caseMbaKey1 = img_caseMbaKey1,
  caseMbaKey2 = img_caseMbaKey2,
  caseMbaKey3 = img_caseMbaKey3,
  caseMbaLogo = img_caseMbaLogo,
  caseMbaPic1 = img_caseMbaPic1,
  caseMbaPic2 = img_caseMbaPic2,
  caseMbaPic3 = img_caseMbaPic3,
  caseMba = img_caseMba,
  caseSkBg = img_caseSkBg,
  caseSkLogo = img_caseSkLogo,
  caseSkPic1 = img_caseSkPic1,
  caseSkPic2 = img_caseSkPic2,
  caseSkPic3 = img_caseSkPic3,
  caseSkPic4 = img_caseSkPic4,
  caseSkResult1 = img_caseSkResult1,
  caseSkResult2 = img_caseSkResult2,
  caseSkResult3 = img_caseSkResult3,
  caseSkResult4 = img_caseSkResult4,
  caseSk = img_caseSk;